import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import axios from 'axios';
import $ from 'jquery';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import Tnc from './comp/tnc';
import Tablemap from '../../assets/img/table-map.png';
import Toastcom from '../components/toastcom';
// import Meta from '../components/meta';
// import API from './../../services';
// import OneSignal from 'react-onesignal';

class Reservation extends Component {

  constructor(props){
    super(props);
    this.state = { loggedin:true, datacust:null, load:false, data:null, property:null, dp:0, table:null, tabledetail:null,roomdetail:null,wabutton:false,
                   mess:null, errortype:'success' }
  }

  componentDidMount(){
    this.token_otentikasi();
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }else{
      this.get_property();
    }
 }

  get_property = async event =>{
    //  console.log("token : ",localStorage.getItem('token'));
       this.setState({ load:true });
        axios.get(API.url+'configuration/', { headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            this.setState({ property: response.data.content });
            this.get_event(); this.load_user();
            // console.log("Hasil response : ",response.data.content);
        })
        .catch((err) => { 
         }); 
  }

  load_user = async() => {
    axios.get(API.url+'customer/get/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      // console.log("Data load user : ",response.data.content);
        this.setState({ datacust: response.data.content});
    })
    .catch((error) => { 
        // error.response.status
        // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
        // swal(error.response.data.error, "", "error");
        // console.log(error);
    }); 
  }

  get_event = async() => { 
      axios.get(API.url+'article/get_by_id/'+this.props.match.params.eventid,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
          this.setState({ data:response.data.content });
          this.get_table();
      })
      .catch((error) => { 
          console.log(error);
      });    
  }

  get_table = async() => { 
      let nilai = {category: ""};
      axios.post(API.url+'reservation/room_table/'+this.props.match.params.eventid,nilai)
      .then((response) => {
          // console.log(response.data.content.result); 
          this.setState({ table:response.data.content.result }, () => this.handleChange());
      })
      .catch((error) => { 
      }); 
  }

  handleChange = () => {
    axios.get(API.url+'reservation/get_room_by_id/'+document.getElementById("ctable").value+"/"+this.props.match.params.eventid,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        let data = response.data.content;
        this.setState({tabledetail:data, roomdetail:data.description, dp:parseInt(data.price/2)});
    })
    .catch((error) => {}); 
  };

  post = async() => { 
    let nilai = {event: this.props.match.params.eventid,table:document.getElementById("ctable").value};
    // console.log(nilai);
    axios.post(API.url+'reservation/add',qs.stringify(nilai),{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        // console.log(response.data.content); 
        this.setState({ mess: "Reservation Successful", errortype: 'success' }, () => toastbox('ttoast',2000));
        // swal("Reservation Successful", "", "success"); 
        let targetURL = `whatsapp://send?text=Reservasi an ${this.state.datacust.fname}, untuk event ${this.state.data.title} pada tanggal ${this.state.data.date} dengan no meja ${this.state.tabledetail.code} sudah kami terima. Silahkan lakukan pembayaran DP50% sebesar Rp ${API.formatNumber(this.state.dp)} dan kirim bukti transfer pembayaran anda. Terimakasih sudah menghubungi ${this.state.property.name}&phone=+${this.state.property.phone1}`;
        setTimeout(() => window.location.href = targetURL, 2000);
        setTimeout(() => this.setState({ wabutton:true}), 3000);
        
    })
    .catch((error) => { 
      // swal(error.response.data.error, "", "error"); 
      this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));
    }); 
  }


  render() {
    // console.log('Data Cust : ',this.state.datacust);
    let comp;  let table; let compbutton; let property; let targetURL;
    
    if (this.state.data !== null && this.state.tabledetail !== null && this.state.property != null && this.state.datacust != null){
       targetURL = `whatsapp://send?text=Reservasi an ${this.state.datacust.fname}, untuk event ${this.state.data.title} pada tanggal ${this.state.data.date} dengan no meja ${this.state.tabledetail.code} sudah kami terima. Silahkan lakukan pembayaran DP50% sebesar Rp ${API.formatNumber(this.state.dp)} dan kirim bukti transfer pembayaran anda. Terimakasih sudah menghubungi ${this.state.property.name}&phone=+${this.state.property.phone1}`;
    }

    if (this.state.wabutton === false){
      compbutton = <button onClick={this.post} className="btn btn-primary btn-block btn-lg"> Make Reservation </button>;
    }
    // else{ compbutton = <a href={targetURL} type="button" className="btn btn-primary btn-block btn-lg">
    //                         <ion-icon name="logo-whatsapp"></ion-icon>
    //                         Chat Our Sales
    //                     </a>; 
    // }

    if (this.state.data !== null){
      comp =  <div className="section mt-2 text-center">
                {/* <p><img src={this.state.data.image} width="100%" alt="img" /></p> */}
                <p><img src={Tablemap} width="100%" alt="img" /></p>
                <h2> {this.state.data.title} </h2>
              </div>;
    }
   
    if (this.state.table !== null){
      table = <select className="form-control" id="ctable" onChange={this.handleChange}>
{
this.state.table.map((dynamicData,index) => {
            return (
              <option key={index} value={dynamicData.id}>{dynamicData.code} - Rp {API.formatNumber(dynamicData.price)}</option>    
            );
        })
}
              </select>;
    }

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.wabutton === true){ return <Redirect to='/'></Redirect>}
    return (
      <>
        {/* <Meta/> */}
        <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <a href="/" className="headerButton goBack">
                <ion-icon name="chevron-back-outline" /> Back
              </a>
            </div>
          </div>

        <div id="appCapsule">

        {comp}

        <div className="section mb-5 p-2">
          
            <div className="card">
              <div className="card-body pb-1">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1"> Select Table </label>
                   {table}
                    <i className="clear-input">
                      <ion-icon name="close-circle" />
                    </i>
                  </div>

                  <div className="input-wrapper">
                  <br/>
                  <p> {this.state.roomdetail} </p>
                  </div>

                 <Tnc property={this.state.property} dp={this.state.dp}/>

                </div>
              </div>
            </div>
            <div className="form-button-group  transparent">
              {compbutton}
              {/* <button onClick={this.post} className="btn btn-primary btn-block btn-lg"> Make Reservation </button> */}


              {/* <button type="button" class="btn btn-primary btn-md btn-block me-1 mb-1">
                  <ion-icon name="logo-whatsapp"></ion-icon>
                  Make Reservation
              </button> */}

            </div>
          
        </div>
         {/* toast */}
         <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}
      </div>
        
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Reservation;
