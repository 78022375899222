import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../../services';
import axios from 'axios';
import $ from 'jquery';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
// import Meta from '../components/meta';
// import API from './../../services';
// import OneSignal from 'react-onesignal';

class Tnc extends Component {

  constructor(props){
    super(props);
    this.state = { property:null }
  }

  componentDidMount(){}

  render() {
    // console.log('Data : ',this.props.property);
    let bank; let accno; let accname;
    if (this.props.property !== null){
        bank = this.props.property.bank; accno = this.props.property.account_no; accname = this.props.property.account_name;
    }
    
    return (
      <>
        <div className="input-wrapper">
        <h5> Kebijakan Pemesanan Umum: </h5>
        <ol>
            <li> Harap diperhatikan bahwa Sofa/Table Anda hanya akan ditahan selama 30 menit dari waktu pemesanan Anda.Segera lakukan pembayaran DP 50% dan lampirkan bukti transfer ke rekening {bank} {accno} a.n. {accname} sebesar <h5>Rp {API.formatNumber(this.props.dp)}</h5> </li>
            {/* <li> Dalam kasus kedatangan terlambat atau ketidakdatangan, harap memberitahu Receptionist terlebih dahulu. </li> */}
            <li> Lastcall123 berhak untuk membatalkan pemesanan Anda jika lebih dari pukul 21.00 belum tiba di lastcall123 akan diberikan toleransi 30 Menit dari jam yang telah diberikan oleh pihak lastcall123. Lewat dari waktu yang telah diberikan kepada cust, pihak manajemen berhak menjual sofa/table tsb kepada waiting list cust. </li>
            <li> Pembatalan customer yang telah melakukan pembayaran DP akan di bebankan 20% dari DP. Sisanya akan dikembalikan kepada cust tsb. </li>
        </ol>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Tnc;
