import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import qs from 'qs';
import Toastcom from '../components/toastcom';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datakab: [], load: false, kabid: 0, district: 0, verify: false, custid: 0, checked:false,
      mess:null, errortype:'success', displaybutton:true
    };
    localStorage.removeItem('token');
  }

  componentDidMount(){
     this.load_kabupaten();
    //  console.log(API.url);
  }

  load_kabupaten = async() => {
    this.setState({ load:false });
    axios.get(API.url+'city/get_city_rj')
      .then((response) => {
          this.setState({ datakab: response.data.content.result, load:true });
      })
      .catch((error) => { 
          // console.log(error);
      }); 
  }

  handleChange = (selectedOption) => {
    // console.log(`Option selected:`, selectedOption.value)
     this.setState({ load:false, kabid: selectedOption.value });
  };

  handleCheckbox = event => {
    let checked = false;
    if (event.target.checked) {
      checked = true;
    }
    this.setState({ checked:checked});
  };

  login = async() =>{

    this.setState({ displaybutton: false});
        
    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let phone = document.getElementById("phone").value;
    let city = this.state.kabid;
    let district = this.state.district;
    let address = document.getElementById("address").value;
    let zip = document.getElementById("zip").value;
    let password = document.getElementById("password").value;
    let password2 = document.getElementById("password2").value;
    let dob = document.getElementById("dob").value;
    let mess = null;

    if (password !== password2){ mess = 'Password Not Match'; }
    if (this.state.checked === false){ mess = "Agreement term & condition not checked"; }

    if (mess === null){
     let requestBody = {tname: name,taddress: address, tzip:zip, tphone1: phone, temail: email, ccity:city, cdistrict:district, tpassword:password, tdob:dob}
      // console.log(requestBody);
      
      this.setState({ load:false })
      axios.post(API.url+'customer/add',qs.stringify(requestBody))
        .then((response) => {
            // console.log(response.data.content); 
            // toast.success("Registrasi Berhasil",
            //     {position: toast.POSITION.BOTTOM_CENTER,
            //     autoClose: 2000,
            //     hideProgressBar: false});
            
            // swal("registration success", "", "sucess");   
            this.setState({ mess: "registration success", errortype: 'success' }, () => toastbox('ttoast',3000));

            setTimeout(
              () => this.setState({ verify: true, custid:response.data.content.id }), 
              4000
            );
            
        })
        .catch((error) => { 
          
          // console.log("Error : ", error.response.data.error);

          // toast.error(error.response.data.error,
          //       {position: toast.POSITION.BOTTOM_CENTER,
          //       autoClose: 2000,
          //       hideProgressBar: false});
            // swal(error.response.data.error, "", "error");   
            this.setState({ mess: error.response.data.error, errortype: 'error', displaybutton:true}, () => toastbox('ttoast',3000));
        }); 
          // axios
    }else{ 
      // swal(mess, "", "error"); 
      this.setState({ mess: mess, errortype: 'error',displaybutton:true }, () => toastbox('ttoast',3000));
    }

  }

  vivible_pass = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {x.type = "text";} else {x.type = "password";}
  }


  render() {
    if (this.state.verify === true){ return <Redirect to={`/verify/${this.state.custid}`}></Redirect>}
    // console.log('Display button : ',this.state.displaybutton);

    let displaybut;
    if (this.state.displaybutton === true){
      displaybut =  <button onClick={this.login} type="button" className="btn btn-primary btn-block btn-lg">Register</button>;
    }

    return (
      <>
        {/* <Meta/> */}
        <div>

          <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <a href="/" className="headerButton goBack">
                <ion-icon name="chevron-back-outline" />
              </a>
            </div>
            <div className="pageTitle" />
            <div className="right">
              <a href="/login" className="headerButton">
                Login
              </a>
            </div>
          </div>

          <div id="appCapsule">
            <form>
            <div className="section mt-2 text-center">
              <h1>Register now</h1>
            </div>
            <div className="section mb-5 p-2">
              
                <div className="card">
                  <div className="card-body">

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Name </label>
                        <input type="text" className="form-control" id="name" placeholder="Your name" required />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Phone </label>
                        <input type="number" className="form-control" id="phone" placeholder="Your phone no" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">E-mail</label>
                        <input type="email" className="form-control" id="email" placeholder="Your e-mail" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">Address</label>
                        {/* <input type="email" className="form-control" id="temail" placeholder="Your e-mail" /> */}
                        <textarea className="form-control" id="address" placeholder="Your address"></textarea>
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Zip </label>
                        <input type="number" className="form-control" id="zip" placeholder="Your zip code" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">City</label> <br/>
                        <Select
                          id='ckab'
                          className=''
                          placeholder="..."
                          onChange={this.handleChange}
                          options={this.state.datakab}
                        />

                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> DOB </label>
                        <input type="date" className="form-control" id="dob" placeholder="Your date of birth" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="password1">Password</label>
                        <input type="password" className="form-control" id="password" autoComplete="off" placeholder="Your password" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                        <div id="btnToggle" className="password-hs" onClick={this.vivible_pass}>
                          <i id="eyeIcon" className="iconly-Hide icli hide" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="password2">Password Again</label>
                        <input type="password" className="form-control" id="password2" autoComplete="off" placeholder="Type password again" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>
                    <div className="custom-control custom-checkbox mt-2 mb-1">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="customCheckb1" onChange={this.handleCheckbox} />
                        <label className="form-check-label" htmlFor="customCheckb1">
                          I agree &nbsp;
                          <a href="#" data-bs-toggle="modal" data-bs-target="#termsModal">
                            terms and
                            conditions
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-button-group transparent">
                {displaybut}
                  {/* <button onClick={this.login} type="button" className="btn btn-primary btn-block btn-lg">Register</button> */}
                </div>
              
            </div>
            </form>
          </div>

          <div className="modal fade modalbox" id="termsModal" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Terms and Conditions</h5>
                  <a href="#" data-bs-dismiss="modal">Close</a>
                </div>
                <div className="modal-body">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum, urna eget finibus
                    fermentum, velit metus maximus erat, nec sodales elit justo vitae sapien. Sed fermentum
                    varius erat, et dictum lorem. Cras pulvinar vestibulum purus sed hendrerit. Praesent et
                    auctor dolor. Ut sed ultrices justo. Fusce tortor erat, scelerisque sit amet diam rhoncus,
                    cursus dictum lorem. Ut vitae arcu egestas, congue nulla at, gravida purus.
                  </p>
                  <p>
                    Donec in justo urna. Fusce pretium quam sed viverra blandit. Vivamus a facilisis lectus.
                    Nunc non aliquet nulla. Aenean arcu metus, dictum tincidunt lacinia quis, efficitur vitae
                    dui. Integer id nisi sit amet leo rutrum placerat in ac tortor. Duis sed fermentum mi, ut
                    vulputate ligula.
                  </p>
                  <p>
                    Vivamus eget sodales elit, cursus scelerisque leo. Suspendisse lorem leo, sollicitudin
                    egestas interdum sit amet, sollicitudin tristique ex. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos himenaeos. Phasellus id ultricies eros. Praesent
                    vulputate interdum dapibus. Duis varius faucibus metus, eget sagittis purus consectetur in.
                    Praesent fringilla tristique sapien, et maximus tellus dapibus a. Quisque nec magna dapibus
                    sapien iaculis consectetur. Fusce in vehicula arcu. Aliquam erat volutpat. Class aptent
                    taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                  </p>
                </div>
              </div>
            </div>
          </div>

        {/* toast */}
        <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}

        </div>

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Register;
