import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import Select from 'react-select';
import swal from 'sweetalert';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mtype from './comp/mtype';
import Toastcom from '../components/toastcom';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Profilesetting extends Component {

  constructor(props){
    super(props);
    this.state = {data:[], datakab:[], datadistrict:[], verify:false, loggedin:true, load:false, city:"", district:"",mess:null, errortype:'success' }
  }

  componentDidMount(){
     this.token_otentikasi();
     this.load_kabupaten();
    //  console.log(API.url);
  }

  token_otentikasi = async() => { 
     const stts = await API.otentikasi();
     if (stts === false){ this.setState({ loggedin:false }) }else{
       this.load_user();
     }
  }

  load_user = async() => {
    axios.get(API.url+'customer/get/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      // console.log("Data load user : ",response.data.content);
        this.setState({ data: response.data.content, city:response.data.content.city, district:response.data.content.district});
        this.load_district();
    })
    .catch((error) => { 
        // error.response.status
        // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
        // swal(error.response.data.error, "", "error");
        // console.log(error);
    }); 
  }

  load_kabupaten = async() => {
    this.setState({ load:false });
    axios.get(API.url+'city/get_city_rj')
      .then((response) => {
          this.setState({ datakab: response.data.content.result, load:true });
      })
      .catch((error) => { 
          // console.log(error);
      }); 
  }

  handleChange = (selectedOption) => {
    this.setState({ city:selectedOption.value });
  };



  update = async() =>{
      
    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let phone = document.getElementById("phone").value;
    let city = this.state.city;
    let district = '0';
    let address = document.getElementById("address").value;
    let zip = document.getElementById("zip").value;

    let requestBody = {tname: name,taddress: address, tzip:zip, tphone1: phone, temail: email, ccity:city, cdistrict:district, twebsite:"", tnpwp:"", tprofession:"", torganization:"", tinstagram:"" }
    // console.log(requestBody);

    // this.setState({ load:false })
    axios.post(API.url+'customer/update',qs.stringify(requestBody),{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
          // console.log(response.data.content); 
          // toast.success("Perubahan Data Profile Berhasil",
          //     {position: toast.POSITION.BOTTOM_CENTER,
          //     autoClose: 2000,
          //     hideProgressBar: false});

          //  swal("Data update successfull","","success");
           this.setState({ mess: "Data update successfull", errortype: 'success' }, () => toastbox('ttoast',3000));

          setTimeout(
            () => this.setState({ verify: true }), 
            3000
          );
          
          // localStorage.setItem('token', response.data.content.token);
          // this.setState({ loggedin:true })
          // alert('login berhasil');
      })
      .catch((error) => { 
        console.log("Error : ", error);
        // swal("Failed to post data","","error");
        this.setState({ mess: "Failed to post data", errortype: 'error' }, () => toastbox('ttoast',3000));
        // toast.error(error.response.data.error,
        //       {position: toast.POSITION.BOTTOM_CENTER,
        //       autoClose: 2000,
        //       hideProgressBar: false});
      }); 
        // axios

  }


  render() {
    // console.log('Data property : ',this.state.user);
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.verify === true){ return <Redirect to={`/verify/${this.state.data.id}`}></Redirect>}
    return (
      <>
        {/* <Meta/> */}

        <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <a href="/profile" className="headerButton goBack">
                <ion-icon name="chevron-back-outline" />
              </a>
            </div>
          </div>

          <div id="appCapsule">
            <div className="section mt-2 text-center">
             <h3 className="text-center">My Profile</h3>
            </div>

            <div className="section mt-3 text-center">
              <div className="avatar-section">
                <a href="#">
                  <img src="assets/img/avatar1.jpg" alt="avatar" className="imaged w100 rounded" />
                  <span className="button">
                    <ion-icon name="camera-outline" />
                  </span>
                </a>
              </div>
            </div>
           
            <form>
            <div className="section mb-5 p-2">
              
                <div className="card">
                  <div className="card-body">

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Name </label>
                        <input type="text" className="form-control" id="name" placeholder="Your name" defaultValue={this.state.data.fname}  required />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Phone </label>
                        <input type="number" className="form-control" id="phone" placeholder="Your phone no" defaultValue={this.state.data.phone1} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">E-mail</label>
                        <input type="email" className="form-control" id="email" placeholder="Your e-mail" defaultValue={this.state.data.email} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">Address</label>
                        {/* <input type="email" className="form-control" id="temail" placeholder="Your e-mail" /> */}
                        <textarea className="form-control" style={{height: '100px'}} id="address" placeholder="Address" defaultValue={this.state.data.address}></textarea>
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Zip </label>
                        <input type="number" className="form-control" id="zip" placeholder="Your zip code" defaultValue={this.state.data.zip} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">City</label> <br/>
                        <Select
                          value={this.state.datakab.filter(({value}) => value === this.state.city)}
                          placeholder="..."
                          onChange={this.handleChange}
                          options={this.state.datakab}
                        />

                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-button-group transparent">
                  <button onClick={this.update} type="button" className="btn btn-primary btn-block btn-lg">Update Profile</button>
                </div>
              
            </div>
            </form>

        {/* toast */}
         <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}

          </div>

         
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Profilesetting;
