import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../services';
// import Category from './comp/category';
// import style from '../../../public/assets/css/style.css';
import image404 from './error-404.png';
// import Meta from '../components/meta';

class Notfound extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    // this.goBack();
  }

   goBack = () => { 
     setTimeout(
      () => this.props.history.goBack(), 
      7000
     );
   }


  render() {

    this.goBack();

    // console.log("Isi data state : ",this.state.data);
    return (
      <>
      {/* <Meta/> */}

      {/* App Header */}
      <div className="appHeader no-border">
          <div className="left">
            <a onClick={this.goBack} className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle">
            404 Page
          </div>
          <div className="right">
          </div>
        </div>
        {/* * App Header */}
        {/* App Capsule */}
        <div id="appCapsule">
          <div className="section">
            <div className="splash-page mt-5 mb-5">
              <h1>404</h1>
              <h2 className="mb-2">Page not found!</h2>
              <p>Sorry, the page you were looking for could not be found.</p>
            </div>
          </div>
          <div className="fixed-bar">
          </div>
        </div>
        {/* * App Capsule */}


                {/* empty cart start */}
      {/* <section className="px-15">
        <div className="empty-cart-section text-center">
          <img src={image404} className="img-fluid" alt="" />
          <h1> That page doesn't exist! </h1>
          <p>Sorry, the page you were looking for could not be found.</p>
          <a href="/" className="btn btn-solid w-100">Back to Home</a>
        </div>
      </section> */}
      {/* empty cart end */}



      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Notfound;
