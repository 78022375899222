import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';

class Toastcom extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
  }


  closed = async() =>{
    tutupToastBox('ttoast');
  }

  render() {
    let type;
    if (this.props.type === 'success'){
        type = <ion-icon name="checkmark-circle" className="text-success" />;
    }else{
        type = <ion-icon name="close-circle-outline" className="text-success" />; 
    }
    return (
      <>        
        {/* toast */}
        <div id="ttoast" className="toast-box toast-center">
        <div className="in">
          {type}
          {/* <ion-icon name="checkmark-circle" className="text-success" /> */}
          {/* <ion-icon name="close-circle-outline" className="text-success" /> */}
          <div className="text">
           {this.props.data}
          </div>
        </div>
        <button onClick={this.closed} type="button" className="btn btn-sm btn-text-light close-button">CLOSE</button>
      </div>
        {/* toast */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Toastcom;
