/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert';
import axios from 'axios';
import API from '../../../services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line react/prefer-stateless-function
class Header extends Component {

  constructor(props){
    super(props);
    this.state = {data:[], property:null, loggedin:true, notif:0, load:false }
  }

  componentDidMount(){
     this.token_otentikasi();
    //  console.log(API.url);
  }

  token_otentikasi = async() => { 
     const stts = await API.otentikasi();
     if (stts === false){ this.setState({ loggedin:false }) }else{
      this.load_user();
      this.load_notif();
     }
  }

  load_user = async() => {
    axios.get(API.url+'customer/get/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      // console.log("Data load user : ",response.data.content);
        this.setState({ data: response.data.content});
    })
    .catch((error) => { 
        // error.response.status
        // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
        // swal(error.response.data.error, "", "error");
        // console.log(error);
    }); 
  }

  load_notif = (type=0) => {
    axios.post(API.url+'customer/notif',{type:"", campaign:"" ,read:"0", limit: 50, offset:0},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      //  console.log("State length : ",response.data.content);
       if (response.data.content !== null){
          this.setState({ notif: response.data.content.length }); 
       }
    })
    .catch((error) => { 
        console.log(error);
    }); 
}

  render() {
    // if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    //  console.log('Hasil header login : ',this.props.loginstatus);
     let name = "Guest";
     if (this.props.loginstatus === true){
      name = this.state.data.fname;
     }
    return (
      <div className="appHeader text-gold">
        <div className="left">
          <a className="headerButton" data-bs-toggle="modal">
            <img src="/assets/img/avatar1.jpg" alt="image" className="imaged rounded w32" /> &nbsp;Hi {name}
          </a>
        </div>
        <div className="right">
          <a href="/notif" className="headerButton">
            <ion-icon className="icon" name="notifications-outline" />
            <span className="badge badge-danger">{this.state.notif}</span>
          </a>
          {/* <a onClick={this.logout} className="headerButton">
            <ion-icon className="icon" name="exit-outline" />
          </a> */}
        </div>
      </div>
    );
  }
}

export default Header;
