/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// eslint-disable-next-line react/prefer-stateless-function
class Modalbs extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], load: false, show: false };
  }

  componentDidMount() {
    // console.log("Article ID : ",this.props.article);
  }

  render() {
    let str; let comp; let date; let payment; let reserv;

    if (this.props.article !== null) {
      const data = this.props.article;

      if (data.text !== undefined) {
        str = data.text.replace('\\"', '"');
      }

      if (data.category === 'Event') {
        date = (
          <div>
            <strong> Event Date </strong>
            <div className="text-small text-secondary text-gold">{data.date}</div>
          </div>
        );
        payment = (
          <div className="text-left">
            <strong>Payment Type</strong>
            <div className="text-small text-secondary text-gold"> Booking Fee </div>
          </div>
        );
        reserv = <a href={`/reservation/${data.id}`} className="btn btn-primary btn-block btn-lg"> Sofa / Table </a>;
      }

      comp = (
        <Modal show={this.props.show} onHide={this.props.handleModal}>
          <Modal.Header>
            <a onClick={this.props.handleModal} data-bs-dismiss="modal">Close</a>
          </Modal.Header>
          <Modal.Body>
            <p><img src={data.image} width="100%" alt="img" /></p>
            <h3>{data.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: str }} />
            <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              <li>
                <div className="item">
                  <div className="in">
                  {date}
                  {payment}
                    {/* <div>
                      <strong> Event Date </strong>
                      <div className="text-small text-secondary text-gold">{data.date}</div>
                    </div>
                    <div className="text-left">
                      <strong>Payment Type</strong>
                      <div className="text-small text-secondary text-gold"> Booking Fee </div>
                    </div> */}
                  </div>
                </div>
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={this.props.handleModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.props.handleModal}>
                  Save Changes
                </Button> */}
            {reserv}
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <>
        {comp}
      </>
    );
  }
}

export default Modalbs;
