import axios from 'axios';
import swal from 'sweetalert';
import db from './db.jsx';

// const qs = require('querystring');
const url = 'https://lastcallapi.dswip.com/';

const Get = (path) => {
  const promise = new Promise((resolve, reject) => {
    axios.get(url + path, { headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => { resolve(response.data); })
      .catch((error) => { reject(error.response); });
  });
  return promise;
};

const Post = (path, data) => {
  const promise = new Promise((resolve, reject) => {
    axios.post(localStorage.getItem('url') + path, data, { headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((result) => { resolve(result.data); },
        (err) => { reject(err); });
  });
  return promise;
};

const Post_form = (path, data) => {
  const promise = new Promise((resolve, reject) => {
    axios.post(localStorage.getItem('url') + path, qs.stringify(data), { headers: { 'X-Auth-Token': localStorage.getItem('token'), 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((result) => { resolve(result.data); },
        (err) => { reject(err); });
  });
  return promise;
};

const formatNumber = (num = 0) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

const today = () => {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  today = `${yyyy}-${mm}-${dd}`; return today;
};

const today_time = () => {
  const today = new Date();
  const time = `${today.getHours()}:${today.getMinutes()}`;
  return time;
};

const capitalize = (lower = '') => lower.charAt(0).toUpperCase() + lower.substring(1);

const dateformat = (date, spliter = '/') => {
  if (date) {
    // 2020-03-21
    if (spliter === '-') {
      var fields = date.split(spliter);
      return `${fields[2]}-${fields[1]}-${fields[0]}`;
    }
    var fields = date.split(spliter);
    return `${fields[2]}-${fields[0]}-${fields[1]}`;
  } return '';
};

const get_data_db = async () => {
  const allPosts = await db.posts.toArray();
  return allPosts;
};

const post_error_db = async (modul = '', error = '', code = 401) => {
  const postdata = {
    date: today(), time: today_time(), modul, error, code,
  };
  db.errors.add(postdata).then(async () => true);
};

const SwalHTML = (html) => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  swal({
    title: 'Error!',
    content: wrapper,
    icon: 'error',
    timer: 3000,
  });
};

const SwalTimer = (title = 'Error', data = '', type = 'error', timer = 3000) => {
  swal({
    title,
    text: data,
    icon: type,
    timer,
    button: false,
  });
};

const logout_service = () => {
  if (localStorage.getItem('token') !== null) {
    logout().then((result) => {
      localStorage.removeItem('token');
    }).catch((err) => {
      swal('Request Error', '', 'error');
      localStorage.removeItem('token');
      // console.log("ini error : ", err.data.error);
    });
  } else { localStorage.removeItem('token'); }
};

// service  / modul

const logout = () => Get('login/logout');
// const property = () => Get('configuration');
const customer = (data) => Get('customer/get', data);
const city = () => Get('configuration/get_city_db');
const category = () => Get('category');
const payment = () => Get('payment');
// const product = (uid) => Get(`product/get/${uid}`);
const tax = () => Get('tax');
const report = () => Get('pos/report');

// eslint-disable-next-line no-return-await
const decode = async () => await Get('customer/decode_token').then((result) => result.content).catch((error) => null);
const product = async (pid) => await Get(`product/get/${pid}`).then((result) => result.content).catch((error) => null);
const property = async () => await Get(`configuration`).then((result) => result.content).catch((error) => null);

const product_details = async (pid, type) => {
  const pro = await product(pid);
  if (type === 'name') { return pro.name; }
  if (type === 'sku') { return pro.sku; }
  if (type === 'image') { return pro.image; }
};

const otentikasi_acl = async (data = '') => {
  const stts = await otentikasi();
  if (stts === true) {
    // swal('Dont have privilege','Unauthorize Request - '+data,'warning');
    SwalTimer('Dont have privilege', `Unauthorize Request - ${data}`, 'warning');
  } else { logout_service(); }
};

const otentikasi = async () => {
  if (localStorage.getItem('token') === null) { return false; }

  // let url = localStorage.getItem('url');
  // const stts = axios.get(url+"login/decode_token",{headers: {'X-Auth-Token': localStorage.getItem('token')}})
  // .then((response) => { return true; }).catch((error) => { return false; });

  return await Get('customer/decode_token').then((result) => true).catch((error) => false);
  // return stts;
};

const API = {
  otentikasi,
  otentikasi_acl,
  logout_service,
  property,
  customer,
  product_details,
  city,
  category,
  payment,
  tax,
  report,
  SwalHTML,
  SwalTimer,
  formatNumber,
  capitalize,
  decode,
  get_data_db,
  Post_form,
  dateformat,
  url,
  today,
  today_time,
  post_error_db,
};

export default API;
