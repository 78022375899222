import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import Header from './comp/header';
import Wallet from './comp/wallet';
import News from './comp/news';
import Newsone from './comp/newsone';
import Bottommenu from '../components/bottommenu';
// import Meta from '../components/meta';
// import API from './../../services';
// import OneSignal from 'react-onesignal';

class Home extends Component {

    constructor(props){
      super(props);
      this.state = { data:[],load:false,loggedin:true }
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      // let onesignal = await OneSignal.init({ appId: 'd9db449d-db37-47ce-bccc-92eed5fd9f14' });
      // // OneSignal.init({
      // //   appId: "YOUR-APP-ID-HERE"
      // // });
      // OneSignal.on('subscriptionChange', function(isSubscribed) {
      //   console.log("The user's subscription state is now:", isSubscribed);
      // });
       const stts = await API.otentikasi();
       if (stts === true){ console.log('Login Successfull'); }
       else if(stts === false){ 
         console.log("Login Failed");
         this.setState({ loggedin:false })
        }
    }

  render() {
    // if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    let comp;
    // setTimeout(() => comp = <News category="24" title="Event"/>, 2000);
    return (
      <>
        {/* <Meta/> */}
        <Header loginstatus={this.state.loggedin}/>
    
        <div id="appCapsule">
          
           <Wallet/>
           {/* {comp} */}
           <News category="24" title="Event"/> 
           {/* <News category="26" title="Promo"/> */}
           <Newsone category="26" title="Promo"/>
           <Newsone category="27" title="News"/>
        </div>
        <Bottommenu/>
        {/* <h1> Home </h1> */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;
