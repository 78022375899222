import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import axios from 'axios';
import $ from 'jquery';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import Toastcom from '../components/toastcom';
// import Meta from '../components/meta';
// import API from './../../services';
// import OneSignal from 'react-onesignal';

class Login extends Component {

  constructor(props){
    super(props);
    this.state = { loggedin:false, load:false, mess:null, errortype:'success', status:0, userid:0 }
    localStorage.removeItem('token'); localStorage.removeItem('phone'); localStorage.removeItem('os-user');
  }

  componentDidMount(){}

  handleInput=(e)=>{
    if (e.key === 'Enter'){ this.login(); }
  }

  login = async() =>{
      
    let user = document.getElementById("username").value;
    let pass = document.getElementById("password").value;
    let device = localStorage.getItem('os-user');
    let nilai = {username: user,password: pass,device:device};

    if (user !== "" && pass !== ""){
        this.setState({ load:false })
        axios.post(API.url+'customer/login',nilai)
          .then((response) => {
              console.log(response.data.content); 
              localStorage.setItem('token', response.data.content.token);
              this.setState({ loggedin:true, status:response.data.content.status, userid:response.data.content.userid })
              // alert('login berhasil');
          })
          .catch((error) => { 
              // error.response.status
              // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
              
              this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));
              // swal(error.response.data.error,"","error");

              // console.log("INi error : ",error.response.data.error);
              this.setState({ load:true })
              $("#username").focus();
          }); 

    }else{ 
          // alert('username & password required');
         // swal('username & password required',"","error");
        this.setState({ mess: "Username & password required", errortype: 'error' }, () => toastbox('ttoast',3000));
        $("#username").focus(); 
     }
  }

  render() {
    // console.log('Status : ', this.state.status);
    // console.log('Userid : ', this.state.userid);
    if (this.state.loggedin === true && this.state.status === 1){ return <Redirect to='/'></Redirect>}
    else if(this.state.loggedin === true && this.state.status === 0){ return <Redirect to={`/verify/${this.state.userid}`}></Redirect> }
    return (
      <>
        {/* <Meta/> */}
        <div id="appCapsule">
        <div className="section mt-2 text-center">
          <h1>Log in</h1>
        </div>
        <div className="section mb-5 p-2">
          
            <div className="card">
              <div className="card-body pb-1">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1">E-mail / Phone No</label>
                    <input type="email" className="form-control" id="username" placeholder="Your e-mail / phone no" onKeyUp={this.handleInput} />
                    <i className="clear-input">
                      <ion-icon name="close-circle" />
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="password1">Password</label>
                    <input type="password" className="form-control" id="password" autoComplete="off" placeholder="Your password" onKeyUp={this.handleInput} />
                    <i className="clear-input">
                      <ion-icon name="close-circle" />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-links mt-2">
              <div>
                <a href="/register">Register Now</a>
              </div>
              <div><a href="/forgot" className="text-muted">Forgot Password?</a></div>
            </div>
            <div className="form-button-group  transparent">
              <button onClick={this.login} className="btn btn-primary btn-block btn-lg">Log in</button>
            </div>

        </div>
        
        {/* toast */}
        <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}

      </div>
        
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Login;
