/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import API from '../../../services';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import qs from 'qs';
import Toastcom from '../../components/toastcom';

// eslint-disable-next-line react/prefer-stateless-function
class Modalbs extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], load: false, show: false,mess:null, errortype:'success' };
  }

  componentDidMount() {
    // console.log("Article ID : ",this.props.article.id);
  }

  post = async() => { 
    let nilai = {product:this.props.article.id};
    // console.log(nilai);
    axios.post(API.url+'redeem/add',qs.stringify(nilai),{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        // console.log(response.data.content); 
        // swal("Redeem Successful", "", "success"); 
        this.setState({ mess: "Redeem Successful", errortype: 'success' }, () => toastbox('ttoast',3000));
        
    })
    .catch((error) => { 
      swal(error.response.data.error, "", "error"); 
      this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));
    }); 
  }

  render() {
    let str; let comp; let date; let payment; let reserv;

    if (this.props.article !== null) {
      const data = this.props.article;

      if (data.text !== undefined) {
        str = data.description.replace('\\"', '"');
      }

      
        date = (
          <div>
            <strong> Valid Until </strong>
            <div className="text-small text-secondary text-gold">{data.date}</div>
          </div>
        );
        payment = (
          <div className="text-left">
            <strong>Price</strong>
            <div className="text-small text-secondary text-gold"> {data.price} LCPoints </div>
          </div>
        );
        reserv = <a onClick={this.post} className="btn btn-primary btn-block btn-lg"> Redeem Points </a>;
      

      comp = (
        <Modal show={this.props.show} onHide={this.props.handleModal}>
          <Modal.Header>
            <a onClick={this.props.handleModal} data-bs-dismiss="modal">Close</a>
          </Modal.Header>
          <Modal.Body>
            <p><img src={data.image} width="100%" alt="img" /></p>
            <h3 className="text-center text-gold">{API.capitalize(data.name)}</h3>
            <div dangerouslySetInnerHTML={{ __html: str }} />
            <p> Tukarkan LCpoint dengan berbagai makanan dan minuman Lastcall123! </p>
            <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              <li>
                <div className="item">
                  <div className="in">
                  {/* {date} */}
                  {payment}
                  </div>
                </div>
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={this.props.handleModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.props.handleModal}>
                  Save Changes
                </Button> */}
            {reserv}
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <>
        {comp}
         {/* toast */}
         <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}
      </>
    );
  }
}

export default Modalbs;
