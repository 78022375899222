import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import Toastcom from '../components/toastcom';
// import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Verify extends Component {

    constructor(props){
      super(props);
      this.state = {data:[], loggedin:false, displayotp:false, displayotplink:false, load:false, mess:null, errortype:'success', displaybutton:true }
      localStorage.removeItem('token');
    }

    componentDidMount(){
       this.get_customer();
    }

     get_customer = async() => {
          axios.get(API.url+'customer/get_by_id/'+this.props.match.params.custid)
            .then((response) => {
                this.setState({
                    data:response.data.content  
                },() => {
                  this.req_otp();
                });
            })
            .catch((error) => { 
                this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));   
                // console.log('Error fetching cust : ',error.response.data.error);
                this.setState({ loggedin:true })
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
            }); 
     }

    handleInput=(e)=>{
      if (e.key === 'Enter'){ this.login(); }
    }

    counter_link = async() =>{
       setTimeout(
         () => this.setState({ displayotplink: true }), 
         30000
       );
    }

    req_otp = async() => {
      let user = this.state.data.email;
      let nilai = {username: user};

      if (user !== ""){
          this.setState({ load:false, displayotplink:false, displaybutton:true })
          axios.post(API.url+'customer/req_otp',nilai)
            .then((response) => {
                // console.log(response.data.content); 
                this.setState({ displayotp:true })
                this.counter_link();

                this.setState({ mess: response.data.content, errortype: 'success' }, () => toastbox('ttoast',3000));   
                // alert('login berhasil');
            })
            .catch((error) => { 
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
                this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));   
                // console.log("INi error : ",error.response.data.error);
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ 
             this.setState({ mess: 'Username required', errortype: 'error' }, () => toastbox('ttoast',3000));   
             $("#username").focus(); 
          }
    }

     verify = async() => {
       this.setState({ displaybutton:false });
       let otp = document.getElementById("otp").value;
        axios.get(API.url+'customer/verify/'+this.props.match.params.custid+"/"+otp)
          .then((response) => {
              // console.log(response.data.content); 
              this.setState({ mess: "Your account has been verified", errortype: 'success' }, () => toastbox('ttoast',3000));  
                setTimeout(
                  () => this.setState({ loggedin: true }), 
                  5000
                );
              
          })
          .catch((error) => { 
             this.setState({ displaybutton:true });
             this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));  
          }); 
     }

  render() {

     let otplink; let buttonverify;
     if (this.state.displayotplink === true){
        otplink =  <h4> Not received otp code? <br/> <a onClick={this.req_otp} style={{color: 'red'}}> Request again. </a> </h4>;
     }
     if (this.state.displaybutton === true){
       buttonverify = <button onClick={this.verify} type="button" className="btn btn-primary btn-block btn-lg">Verify</button>;
     }

    if (this.state.loggedin === true){ return <Redirect to='/login'></Redirect>}

    return (
      <>
        {/* <Meta/> */}
         {/* App Capsule */}
        <div id="appCapsule">
            <div className="section mt-2 text-center">
            <h1>Enter SMS Code</h1>
            <h4>Enter 4 digit sms verification code</h4>
            </div>
            <div className="section mb-5 p-2">
            <form action="index.html">
                <div className="form-group basic">
                <input type="text" className="form-control verification-input" id="otp" placeholder="••••" maxLength={4} />
                </div>
                <div className="form-button-group transparent">
                {/* <button onClick={this.verify} type="button" className="btn btn-primary btn-block btn-lg">Verify</button> */}
                {buttonverify}
                </div>
                <div className="form-group basic mt-2 text-center">
                  {otplink}
                </div>
            </form>
            </div>

            {/* toast */}
            <Toastcom data={this.state.mess} type={this.state.errortype}/>
            {/* toast */}

        </div>
        {/* * App Capsule */}
      </>
    );
  }
}

export default Verify;
