import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Badges from './comp/badges';
import Modalbs from './comp/modalbsnotif';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Notif extends Component {
  constructor(props) {
    super(props);
    this.state = {load: false, data:[], notifdetail:null, show:false, loggedin:true};
  }

  componentDidMount(){
     this.token_otentikasi();
    //  console.log(API.url);
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }else{
    this.load_notif();
    }
  }

  load_notif = (type=0) => {
    axios.post(API.url+'customer/notif',{type:"", campaign:"" ,read:"", limit: 50, offset:0},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      //  console.log("State length : ",response.data.content);
          this.setState({ data: response.data.content }); 
    })
    .catch((error) => { 
        console.log(error);
    }); 
  }

  handleModal = async(event) => { 
      axios.get(API.url+'customer/notif_detail/'+event,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
          this.setState({  notifdetail: response.data.content }, () => this.setModal());
      })
      .catch((error) => { 
          console.log(error);
      });    
  }

  setModal = async() =>{
    let stts;
    if (this.state.show === false){ stts = true;}else{ stts = false; }
    this.setState({ show:stts })
  }

  render() {
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    // console.log('Data : ',this.state.data.length);
    let comp;
    return (
      <>
        {/* <Meta/> */}
        {/* App Header */}
        <div className="appHeader">
          <div className="left">
            <a href="/" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle">
            Notifications
          </div>
        </div>
        {/* * App Header */}

        {/* App Capsule */}
        <div id="appCapsule">

        <Modalbs show={this.state.show} handleModal={this.setModal} article={this.state.notifdetail} />

          <div className="section full">
            <ul className="listview image-listview flush">

{
  this.state.data.map((dynamicData,index) => {
            return (
              <li key={index}>
                <a onClick={() => this.handleModal(dynamicData.id)} className="item">
                  {/* <div className="icon-box bg-success">
                    <ion-icon name="chatbubble-outline" />
                  </div> */}
                  <Badges status={dynamicData.reading}/>
                  <div className="in">
                    <div>
                      <div className="mb-05"><strong>{dynamicData.subject}</strong></div>
                      {/* <div className="text-small mb-05">You have new messages from John</div> */}
                      <div className="text-xsmall">{dynamicData.created}</div>
                    </div>
                  </div>
                </a>
              </li>           
            );
        })
}
            
              {/* <li>
                <a href="app-notification-detail.html" className="item">
                  <div className="icon-box bg-success">
                    <ion-icon name="chatbubble-outline" />
                  </div>
                  <div className="in">
                    <div>
                      <div className="mb-05"><strong>New Messages</strong></div>
                      <div className="text-small mb-05">You have new messages from John</div>
                      <div className="text-xsmall">5/3/2020 10:30 AM</div>
                    </div>
                  </div>
                </a>
              </li> */}

            </ul>
          </div>
        </div>
        {/* * App Capsule */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Notif;
