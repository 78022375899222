import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modalbs from './comp/modalbs';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Redeem extends Component {
  constructor(props) {
    super(props);
    this.state = {load: false, data:[],article:null,show:false,loggedin:true};
  }

  componentDidMount(){
     this.token_otentikasi();
    //  console.log(API.url);
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }else{
    this.load_product();
    }
  }

  load_product = () => {
    axios.post(API.url+'product',{orderby:"",order:"asc",limit: 50,offset:0},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      this.setState({ data: response.data.content.result }); 
    })
    .catch((error) => { 
        console.log(error);
    }); 
 }

 handleModal = async(event) => { 
  // console.log("Event : ",event);

    axios.get(API.url+'product/get/'+event+'/1',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        this.setState({  article: response.data.content }, () => this.setModal());
    })
    .catch((error) => { 
        console.log(error);
    });    
}

setModal = async() =>{
  let stts;
  if (this.state.show === false){ stts = true;}else{ stts = false; }
  this.setState({ show:stts })
}

  render() {
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    let comp;
    if (this.state.data !== null){
      comp = this.state.data.map((dynamicData,index) => {
              return (
                <div key={index} className="col-6 mb-2">
                  <div className="bill-box">
                    <div className="img-wrapper">
                      <a onClick={() => this.handleModal(dynamicData.sku)} className="item">
                        <img src={dynamicData.image} alt="img" className="image-block imaged w140" />
                      </a>
                    </div>
                    <div className="price">{dynamicData.price} Points</div>
                    <p>{API.capitalize(dynamicData.name)}</p>
                  </div>
                </div>       
              );
          });
    }

    return (
      <>
        {/* <Meta/> */}
       {/* App Header */}
       <div className="appHeader">
          <div className="left">
            <a href="/" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle">
            Redeem Points
          </div>
        </div>
        {/* * App Header */}
        {/* App Capsule */}
        <div id="appCapsule" className="full-height">

          {/* Modal Basic */}
          <Modalbs show={this.state.show} handleModal={this.setModal} article={this.state.article} />
          {/* * Modal Basic */}

          <div className="section tab-content mb-1">           
            <div className="row">

              {comp}

              {/* <div className="col-6 mb-2">
                <div className="bill-box">
                  <div className="img-wrapper">
                    <a href="#" className="item" data-bs-toggle="modal" data-bs-target="#ModalBasic" />
                    <a href="#" className="item" data-bs-toggle="modal" data-bs-target="#ModalBasic"><img src="assets/img/redeem1.jpg" alt="img" className="image-block imaged w140" /></a>
                  </div>
                  <div className="price">750 Points</div>
                  <p>Bacardi Light</p>
                </div>
              </div> */}

            </div>
          </div>
        </div>
        {/* * App Capsule */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Redeem;
