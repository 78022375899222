import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modalbs from '../home/comp/modalbs';
import Badges from './comp/badges';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Pointhistory extends Component {
  constructor(props) {
    super(props);
    this.state = {load: false, data:[],loggedin:true};
  }

  componentDidMount(){
    this.token_otentikasi();
     
    //  console.log(API.url);
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }else{
      this.load_event();
    }
  }

  load_event = () => {
    axios.post(API.url+'customer/ledger',{month:"", year:"", limit: 50, offset:0},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      this.setState({ data: response.data.content.result }); 
    })
    .catch((error) => { 
        console.log(error);
    }); 
}

  render() {
    // console.log('Data : ',this.state.data);
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    let comp;
    if (this.state.data.length > 0){

      comp = this.state.data.map((dynamicData,index) => {
        return (
          <a key={index} className="item">
          <div className="detail">
            {/* <img src="assets/img/sample/brand/1.jpg" alt="img" className="image-block imaged w48" /> */}
            <div>
              <h5>{dynamicData.notes}</h5>
              <strong>{dynamicData.no}</strong>
              <p>{dynamicData.date} : {dynamicData.time}</p>
              {/* <h6>{dynamicData.label_status}</h6> */} <br/>
              {/* <Badges status={dynamicData.amount}/> */}
            </div>
          </div>
          <div className="right">
            {/* <div className="price text-gold">{API.formatNumber(dynamicData.amount)} lcpt</div> */}
            <Badges status={dynamicData.amount}/>
          </div>
        </a>     
                );
       });
    }
   
    return (
      <>
        {/* <Meta/> */}
         {/* App Header */}
         <div className="appHeader">
          <div className="left">
            <a href="/profile" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle">
            Point History
          </div>
        </div>
        {/* * App Header */}
        {/* App Capsule */}
        <div id="appCapsule">
          {/* Transactions */}
          <div className="section mt-2">
            {/* <div className="section-title">Today</div> */}
            <div className="transactions">
              {/* item */}
              {comp}


              {/* <a href="transaction-detail.html" className="item">
                <div className="detail">
                  <img src="assets/img/sample/brand/1.jpg" alt="img" className="image-block imaged w48" />
                  <div>
                    <strong>#00020</strong>
                    <p>14-07-2022 16.30</p>
                  </div>
                </div>
                <div className="right">
                  <div className="price text-gold">11.600.000</div>
                </div>
              </a> */}
              {/* * item */}


            </div>
          </div>
          {/* * Transactions */}
         
          {/* <div className="section mt-2 mb-2">
            <a href="#" className="btn btn-primary btn-block btn-lg">Load More</a>
          </div> */}
        </div>
        {/* * App Capsule */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Pointhistory;
