import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = { load: false, data: [] };
  }

  componentDidMount() {
    //  console.log(API.url);
  }

  render() {
    // console.log('Data : ', this.props.status);
    let stts;
    if (this.props.status === '0'){
       stts =<div className="icon-box bg-danger"> <ion-icon name="chatbubble-outline" /> </div>; 
    }
    else{
      stts =<div className="icon-box bg-success"> <ion-icon name="chatbubble-outline" /> </div>; 
    }
    return (
      <>
        {stts}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Badges;
