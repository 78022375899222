/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import API from '../../../services';
import axios from 'axios';

// eslint-disable-next-line react/prefer-stateless-function
class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = { data:0, load: false };
  }

  componentDidMount() {
    this.load_balance();
  }

  load_balance = () => {
    this.setState({ load:false });
    axios.get(API.url+'customer/ledger',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        this.setState({ data: response.data.content.balance,load:true });
        // console.log(response.data.content.result);
    })
    .catch((error) => { 
        console.log(error);
    }); 
  }

  render() {
    // console.log(this.state.data);
    return (
      <div className="section wallet-card-section pt-1">
        <div className="wallet-card">
          {/* Balance */}
          <div className="balance">
            <div className="left">
              <span className="title">My LastCall Point</span>
              <h1 className="total">{this.state.data}</h1>
            </div>
            <div className="Right">
              <img src="/assets/img/logo.png" width="100px" alt="logo" className="logo" />
            </div>
          </div>
          {/* * Balance */}
          {/* Wallet Footer */}
          <div className="wallet-footer">
            <div className="item">
              <a href="/profile">
                <div className="icon-wrapper bg-light">
                  <ion-icon name="person-outline" />
                </div>
                <strong>Profile</strong>
              </a>
            </div>
            <div className="item">
              <a href="/rhistory">
                <div className="icon-wrapper bg-light">
                  <ion-icon name="reorder-four-outline" />
                </div>
                <strong>Reservation</strong>
              </a>
            </div>
            <div className="item">
              <a href="/transaction">
                <div className="icon-wrapper bg-light">
                  <ion-icon name="receipt-outline" />
                </div>
                <strong>Transaction</strong>
              </a>
            </div>
            <div className="item">
              <a href="/redeem">
                <div className="icon-wrapper bg-light">
                  <ion-icon name="ticket-outline" />
                </div>
                <strong>Redeem</strong>
              </a>
            </div>
          </div>
          {/* * Wallet Footer */}
        </div>
      </div>
    );
  }
}

export default Wallet;
