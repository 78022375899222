import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = { load: false, data: [] };
  }

  componentDidMount() {
    //  console.log(API.url);
  }

  render() {
    // console.log('Data : ', this.props.status);
    let stts = <span className="badge badge-primary">0</span>;
    if (this.props.status < 0){ stts = <span className="badge badge-danger">{this.props.status}</span>; }
    else if (this.props.status > 0){ stts = <span className="badge badge-success">{this.props.status}</span>; }
    return (
      <>
        {stts}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Badges;
